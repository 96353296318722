import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../layout/layout'

import Statistics from '../../svg/statistics.svg'
import Collaboration from '../../svg/retailer-collaboration.svg'

const Page = () => (
        <Layout>

            <Helmet>
                <title>Datafeed - Easy access to product information</title>
                <meta name="description" content="Het Zupr platform ontsluit productinformatie richting de consument. Deze productinformatie is in de meeste gevallen direct afkomstig van de merken, maar soms ook rechtstreeks van retailers." />
            </Helmet>

            <section className="text-center">
                <div className="inner">

                    <div className="text">
                        <h2>Datafeed</h2>
                        <p>The Zupr platform provides access to product information to consumers. In most cases, this product information comes directly from brands, but sometimes also directly from retailers. Since the Zupr platform is set up as an API, it also offers the possibility to link directly to the platform.</p>
                        <h3>How do I deliver product data?</h3>
                        <p>This page describes the way in which data should be supplied.</p>
                    </div>

                </div>
            </section>


            <section className="text-left grey divider image-bottom">
                <div className="inner">

                    <div className="text">
                        <h2>External sources</h2>
                        <p>Zupr already connects with the following sources*:
                          <ul className="check-list">
                            <li><a href="https://www.gs1.nl/sectorafspraken-over-standaarden/betrouwbare-productdata-levensmiddelen-en-drogisterij-agf-en/gs1-13">GS1 datalink</a></li>
                            <li>FashionCloud</li>
                            <li>Channable</li>
                            <li>Icecat</li>
                          </ul>
                          <small>* These sources are updated continuously</small>
                        </p>
                    </div>

                    <div className="image">
                        <Collaboration />
                    </div>

                </div>
            </section>

            <section className="text-default grey">
                <div className="inner">
                    <div className="text">
                        <h3>Product information</h3>
                        <p>If your product information is currently stored in one of these external data sources, it is probably already present in the Zupr platform or can be easily added.</p>
                        <p>If your data is not available in the Zupr platform, the easiest way is to make it available on a URL, so that it can be periodically updated by Zupr. An example of this is the Channable system or other types of PIM systems. In consultation, data can be delivered in <i>Excel (.csv)</i> or in the <i>JSON format</i>.</p>
                    </div>
                </div>
            </section>

            <section className="text-center">
                <div className="inner">
                    <div className="text">
                        <h2>Verified product information</h2>
                        <p>Obviously it is a service for you as a brand to the retailer to make the product information available within Zupr. In this way, a brand as owner has control over the data and it also gets the label verified. If the brand does not supply data, it is also possible at Zupr for a retailer to supply this product information. Read here what the other advantages are of registering as a trademark.</p>
                    </div>
                    <div className="image no-margin">
                        <Statistics />
                    </div>
                </div>
            </section>


            <section className="text-default divider">
                <div className="inner">

                    <div className="text">
                        <h2>Which data is relevant?</h2>
                        <p>Zupr currently supports the product groups Footwear, Clothing, Food and Other. The example below shows the relevant basic data of a product. See below for an explanation of <i>Clothing</i>, <i>Footwear</i> and <i>Food</i>.</p>

                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Example</th>
                                <th>Required</th>
                                <th>Comment</th>
                              </tr>
                              <tr>
                                <th>language</th>
                                <td>string</td>
                                <td>en</td>
                                <td>no</td>
                                <td>The language choice is relevant for eg. the title, description and the suggested retail price. ISO (nl, de, fr, ...)</td>
                              </tr>
                              <tr>
                                <th>barcode</th>
                                <td>string</td>
                                <td>870000000001</td>
                                <td>yes</td>
                                <td>GTIN / EAN (13)</td>
                              </tr>
                              <tr>
                                <th>type</th>
                                <td>string</td>
                                <td>footwear</td>
                                <td>no</td>
                                <td>options: footwear, fashion, food, other</td>
                              </tr>
                              <tr>
                                <th>classification</th>
                                <td>string</td>
                                <td>sneakers</td>
                                <td>no</td>
                                <td>e.g. sneakers, shirts, ...</td>
                              </tr>
                              <tr>
                                <th>title</th>
                                <td>string</td>
                                <td>MyProduct1</td>
                                <td>yes</td>
                                <td>The brand name is not part of the product name.</td>
                              </tr>
                              <tr>
                                <th>brand</th>
                                <td>string</td>
                                <td>MyBrand</td>
                                <td>yes</td>
                                <td>Products of multiple brands are possible within one dataset.</td>
                              </tr>
                              <tr>
                                <th>description</th>
                                <td>string</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</td>
                                <td>no</td>
                                <td>Although this column is not required, mentioning a description improves the relevance of the results for the user.</td>
                              </tr>
                              <tr>
                                <th>images</th>
                                <td>list</td>
                                <td>['https://domain.tld/imgs/prod1a.jpg', 'https://domain.tld/imgs/prod1b.jpg', ...]</td>
                                <td>no</td>
                                <td>If no image is specified, the first image will be from this list used as a <i>hero</i>.</td>
                              </tr>
                            </table>
                        </div>

                        <h3>Optional data</h3>
                        <p>Optionally, the item code and the recommended sales price can also be included as a brand. This data is useful for retailers to easily and quickly add products to their range.</p>
                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Example</th>
                                <th>Required</th>
                                <th>Comment</th>
                              </tr>
                              <tr>
                                <th>itemcode</th>
                                <td>string</td>
                                <td>12345-ABC</td>
                                <td>no</td>
                                <td>unique internal identifier</td>
                              </tr>
                              <tr>
                                <th>price_currency</th>
                                <td>string</td>
                                <td>EUR</td>
                                <td>no</td>
                                <td>ISO: EUR, USD, ...</td>
                              </tr>
                              <tr>
                                <th>price_in_cents</th>
                                <td>int</td>
                                <td>995</td>
                                <td>no</td>
                                <td></td>
                              </tr>
                            </table>
                        </div>

                        <h3>Additional information</h3>
                        <p>For products of the footwear type, it is relevant to add additional information, such as size, color and whether the sole is loose. For this, extra columns must be added per line, as shown below.</p>

                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Example</th>
                                <th>Required</th>
                                <th>Comment</th>
                              </tr>
                              <tr>
                                <th>variation_id</th>
                                <td>string</td>
                                <td>123AB</td>
                                <td>no</td>
                                <td>internal identifier per model</td>
                              </tr>
                              <tr>
                                <th>color_name</th>
                                <td>string</td>
                                <td>Zwartgrijs</td>
                                <td>no</td>
                                <td>ISO (nl, de, fr, ...)</td>
                              </tr>
                              <tr>
                                <th>color</th>
                                <td>list</td>
                                <td>['black', 'grey']</td>
                                <td>no</td>
                                <td>This data is used in the filtering for the consumer. options: white, black, gray, cream, brown, purple, dark-blue, light-blue, green, yellow, orange, red, pink, multicolor</td>
                              </tr>
                              <tr>
                                <th>size</th>
                                <td>string</td>
                                <td>47</td>
                                <td>no</td>
                                <td>vb: XS, M, 47, one-size, ...</td>
                              </tr>
                              <tr>
                                <th>demographic</th>
                                <td>list</td>
                                <td>['male', 'boys']</td>
                                <td>no</td>
                                <td>men, women, unisex, boys, girls, juniors, toddlers, infants</td>
                              </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>


            <section className="text-center divider">
                <div className="inner">

                    <div className="text">
                        <h2>Various options</h2>
                        <p>There are various options for linking your data. Naturally, the preference is for a data link where the data can be refreshed periodically (for example daily). In extreme cases it is possible to do a one-time import, so that your products are known in the Zupr platform. It is also possible to create a link whereby stock information is automatically processed per sales location.</p>
                        <p>Please contact us to find out which option is most appropriate for you.</p>
                    </div>

                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Do you have questions?</h2>
                    <p>For questions or clarification, please contact the Zupr team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011300" className="telephone">+31 (0)85 401 13 00</a>
                    </p>
                </div>
            </section>

    </Layout>
)

export default Page
